/* react-carousel-dots */

.react-carousel-dots-holder {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.react-carousel-dots-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  border: 1px solid black;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.react-carousel-dots-dot.active {
  background-color: black;
}

.dot-holder {
  flex-shrink: 0;
  transition: transform 0.5s ease;
}
.react-carousel-dots-dot.small {
  transform: scale(0.5);
}

.react-carousel-dots-dot {
  border: 1px solid #49372c;
  opacity: 0.5;
}

.react-carousel-dots-dot.active {
  background-color: #49372c;
}

/* react-datepicker */

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__navigation--previous {
  border-right-color: #1ab9c4 !important;
}
.react-datepicker__navigation--next {
  border-left-color: #1ab9c4 !important;
}

/* react-select */

.react-select__value-container > input {
  text-indent: -9999999em;
  font-size: inherit;
  width: 1px;
  color: transparent;
  left: -100px;
  opacity: 0;
  position: relative;
  transform: scale(0);
  background: 0px center;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 0px;
}

.react-select__option:active {
  background-color: #1ea0aa !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 200ms;
}

/* braintree */

.braintree-sheet {
  max-height: unset;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
  padding: 0;
}

.braintree-form__raw-input {
  padding: 0 8px;
}

/* rc-slider */
.rc-slider-dot:first-of-type, .rc-slider-dot:nth-of-type(2) {
  opacity: 0;
}

/* react-sliding-pane */

.slide-pane_from_bottom {
  height: 100%;
  height: -webkit-fill-available;
  margin-top: 0;
}

.slide-pane__overlay {
  z-index: 5;
}
.slide-pane__content {
  background: #f8f6f3;
  padding: 24px 16px;
}

/* react-cropper */
.cropper-bg {
  background-image: none;
  background: #ffffff;
}
.cropper-modal {
  background: #ffffff;
}
