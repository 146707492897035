// loadingCircle

.loadingCircle,
.loadingCircle:after {
  position: relative;
  box-sizing: border-box;
}

.loadingCircle {
  width: 14px;
  height: 14px;
  display: block;
  color: #1ab9c4;
}

.loadingCircle:after {
  content: '';
  width: 100%;
  height: 100%;
  display: inline-block;
  border: 2px solid currentColor;
  border-bottom-color: transparent;
  border-radius: 100%;
  background: transparent;

  animation: ball-clip-rotate 0.75s linear infinite;
}

@keyframes ball-clip-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
