.spinner {
  margin: 0;
  width: 70px;
  text-align: center;
}

.spinner-dark .bounce1,
.spinner-dark .bounce2,
.spinner-dark .bounce3 {
  background-color: #49372c;
}

.spinner-center {
  margin: 50px auto;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ld-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes ld-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ld-container {
    transform: scale(0.5);
  }
}
.ld.ld-fade {
  -webkit-animation: ld-fade 1s infinite linear;
  animation: ld-fade 1s infinite linear;
}

.loading-center {
  margin: 50px auto;
}
