/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2013 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Latinotype
 * License URL: http://www.fontspring.com/fflicense/latinotype
 *
 *
 */

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-black-webfont.eot');
  src: url('./fonts/Antartida-Rounded-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-black-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-black-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-black-webfont.svg#antartida_rounded_blackRg') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-black-Italic-webfont.eot');
  src: url('./fonts/Antartida-Rounded-black-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-black-Italic-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-black-Italic-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-black-Italic-webfont.svg#antartida_rounded_blackitalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-bold-webfont.eot');
  src: url('./fonts/Antartida-Rounded-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-bold-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-bold-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-bold-webfont.svg#antartida_rounded_boldregular') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-bold-Italic-webfont.eot');
  src: url('./fonts/Antartida-Rounded-bold-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-bold-Italic-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-bold-Italic-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-bold-Italic-webfont.svg#antartida_rounded_bolditalic') format('svg');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-medium-webfont.eot');
  src: url('./fonts/Antartida-Rounded-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-medium-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-medium-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-medium-webfont.svg#antartida_rounded_mediumRg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-medium-Italic-webfont.eot');
  src: url('./fonts/Antartida-Rounded-medium-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-medium-Italic-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-medium-Italic-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-medium-Italic-webfont.svg#antartida_rounded_mediumIt') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-light-webfont.eot');
  src: url('./fonts/Antartida-Rounded-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-light-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-light-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-light-webfont.svg#antartida_rounded_lightRg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antartida Rounded';
  src: url('./fonts/Antartida-Rounded-light-Italic-webfont.eot');
  src: url('./fonts/Antartida-Rounded-light-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Antartida-Rounded-light-Italic-webfont.woff') format('woff'),
    url('./fonts/Antartida-Rounded-light-Italic-webfont.ttf') format('truetype'),
    url('./fonts/Antartida-Rounded-light-Italic-webfont.svg#antartida_rounded_lightitalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-ExtraBold.woff');
  src: url('./fonts/OpenSans-ExtraBold.woff') format('woff'), url('./fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-ExtraBoldItalic.woff');
  src: url('./fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
    url('./fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Bold.woff');
  src: url('./fonts/OpenSans-Bold.woff') format('woff'), url('./fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-BoldItalic.woff');
  src: url('./fonts/OpenSans-BoldItalic.woff') format('woff'), url('./fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiBold.woff');
  src: url('./fonts/OpenSans-SemiBold.woff') format('woff'), url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-SemiBoldItalic.woff');
  src: url('./fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
    url('./fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.woff');
  src: url('./fonts/OpenSans-Regular.woff') format('woff'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Italic.woff');
  src: url('./fonts/OpenSans-Italic.woff') format('woff'), url('./fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Medium.woff');
  src: url('./fonts/OpenSans-Medium.woff') format('woff'), url('./fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-MediumItalic.woff');
  src: url('./fonts/OpenSans-MediumItalic.woff') format('woff'),
    url('./fonts/OpenSans-MediumItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Light.woff');
  src: url('./fonts/OpenSans-Light.woff') format('woff'), url('./fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-LightItalic.woff');
  src: url('./fonts/OpenSans-LightItalic.woff') format('woff'),
    url('./fonts/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Base */
html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

/* Box-sizing border-box */
/* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
  box-sizing: border-box;
  background: transparent;
}

a {
  text-decoration: none;
  color: currentColor;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

input:focus {
  outline: none;
}

strong {
  font-weight: bold;
}

i {
  font-style: italic;
}

iframe#illow-banner-widget {
  z-index: 2147483647 !important;
}
